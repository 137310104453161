<template>
  <v-main>
    <v-container class="mt-3">


      <v-sheet rounded elevation="2" class="px-8 py-6">

        <h1>Impressum</h1>

        <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
        <p>Guidance Goaching UG<br />
          Pflockenstra&szlig;e 48<br />
          09376 Oelsnitz/Erzgebirge</p>

        <p><strong>Vertreten durch:</strong><br />
          Roy Spitzner</p>

        <h2>Kontakt</h2>
        <p>Telefon: 017662898483<br />
          E-Mail: info@roy-spitzner.de</p>

        <h2>Angaben zur Berufs&shy;haftpflicht&shy;versicherung</h2>
        <p><strong>Name und Sitz des Versicherers:</strong><br />
          Baloise Sachversicherung Aktiengesellschaft Deutschland<br />
          Basler Stra&szlig;e 4<br />
          61352 Bad Homburg</p>
        <p><strong>Geltungsraum der Versicherung:</strong><br />Deutschland</p>

        <h2>EU-Streitschlichtung</h2>
        <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

        <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
        <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

        <!--
        <h1>Impressum</h1>

        <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
        <p>Roy Spitzner<br />
          Pflockenstra&szlig;e 48<br />
          09376 Oelsnitz</p>

        <h2>Kontakt</h2>
        <p>Telefon: 017662898483<br />
          E-Mail: r.spitzner@mitnorm.com</p>

        <h2>Aufsichtsbeh&ouml;rde</h2>
        <p>IHK Chemnitz<br />
          Stra&szlig;e der Nationen 25<br />
          09111 Chemnitz</p>
        <p><a href="https://www.chemnitz.ihk24.de/" target="_blank" rel="noopener noreferrer">https://www.chemnitz.ihk24.de/</a>
        </p>

        <h2>Angaben zur Berufs&shy;haftpflicht&shy;versicherung</h2>
        <p><strong>Name und Sitz des Versicherers:</strong><br />
          Allianz Versicherungs-AG<br />
          K&ouml;niginstr. 28<br />
          80802 M&uuml;nchen</p>
        <p><strong>Geltungsraum der Versicherung:</strong><br />Deutschland</p>

        <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
        <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
          teilzunehmen.</p>

        <p>Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a></p> -->
      </v-sheet>


      <!--
      <v-card>
        <v-card-title>Impressum</v-card-title>
        <v-card-text>
          <strong>Betreiber der Website</strong>
          <p>Steuerberater Max Meister</p>

          <strong>Adresse</strong>
          <p>Impressumsstraße 7, 22222 Impressumsstadt</p>

          <strong>Vertretung des Unternehmens</strong>
          <p>—</p>

          <strong>Kontakt</strong>
          <p>
            Telefon: +49 30 123456<br>
            Fax: +49 30 123456<br>
            E-Mail: kontakt@max-meister.de<br>
            Internet: www.max-meister.de<br>
          </p>

          <strong>Aufsichtsbehörde</strong>
          <p>—</p>

          <strong>Registereintrag</strong>
          <p>—</p>

          <strong>Standeskammer</strong>
          <p>
            Zuständige Kammer:<br>
            Steuerberaterkammer Hessen<br>
            Bleichstraße 1<br>
            60313 Frankfurt/Main<br>
            Tel: 069/153002-0<br>
            Fax: 069/153002-60<br>
            E-Mail: geschaeftsstelle@stbk-hessen.de<br>
            Link: http://www.stbk-hessen.de<br>
            <br>
            Berufsrechtliche Regelungen:<br>
            Steuerberatungsgesetz (StBerG)<br>
            Durchführungsverordnung (DVStB)<br>
            Steuerberatervergütungsverordnung (StBVV)<br>
            Berufsordnung<br>
            Fachberaterordnung<br>
            <br>
            Die berufsrechtlichen Regelungen können Sie hier einsehen.<br>
          </p>

          <strong>Umsatzsteuer-ID</strong>
          <p>(Wenn vorhanden) Umsatzsteueridentifikationsnummer gemäß § 27a Umsatzsteuergesetz: DE 63527364839</p>

          <strong>Liquidation/Auflösung</strong>
          <p>—</p>
        </v-card-text>
      </v-card>
      -->
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "Impressum"
};
</script>

<style scoped>
@media (min-width: 1264px) {
  .container {
    max-width: 1185px;
  }
}
</style>